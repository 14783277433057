<template>
  <div :class="['workOrderDetail', showFooter ? 'workOrderDetail_pd' : '']">
    <van-form ref="form" input-align="right" error-message-align="right" class="formBox" @submit.native.prevent>
      <div class="detail_item common-block">
        <div class="common-t">
          <div class="common-t-l">{{$t('客户信息')}}</div>
          <div class="common-t-r">
            {{ detail.status === 0 ? $t('未解决') : detail.status === 1 ? $t('待跟进') : detail.status === 2 ? $t('待回访') : $t('已解决') }}
          </div>
        </div>
        <div class="info" style="font-size: 16px;" >{{ detail.customerName }}</div>
        <div class="info">
          <span class="label">手机号：</span>
          <span class="content">{{ detail.customerMobile }}</span>
        </div>
        <div class="info">
          <div class="label">{{ $t('跟进人：') }}</div>
          <span class="content">{{ detail.followUserName }}</span>
        </div>
        <div class="info">
          <div class="label">{{ $t('门店名称：') }}</div>
          <span class="content" style="white-space: nowrap;">{{ detail.dealerName }}</span>
        </div>
      </div>
      <div class="detail_item common-block special">
        <div class="common-t">
          <div class="common-t-l">{{$t('工单信息')}}</div>
        </div>
        <div class="row">
          <div class="info ellipsis">
            <div class="label">{{ $t('满意度类型：') }}</div>
            <div class="content">{{ detail.eventType | eventTypeFilter }}</div>
          </div>
          <div class="info ellipsis">
            <div class="label">{{ $t('满意度单号：') }}</div>
            <div>{{ detail.questionId }}</div>
          </div>
        </div>
        <div class="row">
          <div class="info ellipsis">
            <div class="label">{{ $t('满意度发放时间：') }}</div>
            <div class="content">{{ detail.question?.questionSendTime }}</div>
          </div>
          <div class="info ellipsis">
            <div class="label">{{ $t('满意度填写时间：') }}</div>
            <div class="content">{{ detail.question?.submitTime }}</div>
          </div>
        </div>
        <div class="info">
          <div class="label">{{ $t('满意度问卷链接：') }}</div>
          <a v-if="detail.question?.questionQueryUrl" :href="detail.question?.questionQueryUrl" class="content">{{ detail.question?.questionQueryUrl }}</a>
        </div>
        <div class="info">
          <div class="label">{{ $t('低满备注：') }}</div>
          <div class="content">{{ detail.cccRemark }}</div>
        </div>
      </div>
      <!-- 展示跟进 -->
      <div v-if="showFooter" class="detail_item common-block">
        <div class="common-t">
          <div class="common-t-l">{{$t('跟进信息')}}</div>
        </div>
        <van-field
          key="followUserName"
          v-model="submitParams.followUserName"
          :label="$t('跟进人')"
          :placeholder="$t('请选择跟进人')"
          required
          :readonly="!showFollow"
          :rules="[{required: true}]"
          @click="onSelectSale">
        </van-field>
        <van-field
          key="followRemark"
          v-model="submitParams.followRemark"
          required
          show-word-limit
          :label="$t('跟进结果')"
          :placeholder="$t('请输入跟进结果')"
          :rules="[{ required: true }]"
          maxlength="500"
          :readonly="!showFollow"
          rows="4"
          autosize
          type="textarea"
        />
      </div>
      <!-- 展示跟进历史 -->
      <div v-if="showFollows" class="detail_item common-block">
        <div class="common-t">
          <div class="common-t-l">{{$t('跟进历史')}}</div>
        </div>
        <div v-for="(item, index) in detail.follows" :key="index" class="follow-item">
          <div class="item">
            <span>{{ item.followUserName }}</span>
            <span>{{ item.updateTime }}</span>
          </div>
          <div class="item">{{ $t('跟进结果：') }}</div>
          <div class="item" style="color: rgba(13, 23, 26, 0.45);" >{{ item.followRemark }}</div>
        </div>
      </div>
      <div v-if="showFooter" class="common-footer">
        <van-button class="btn submit" type="submit" @click="onSubmit">{{$t('提交结果')}}</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import loading from '@/utils/loading'
import workOrderServices from '@/services/lowFullWorkOrder'
import { mapGetters } from 'vuex'
import { detail } from '@/services/approveManage'
import selectSalesperson from '@/components/select-salesperson'
export default {
  filters: {
    eventTypeFilter(val) {
      const obj = {
        'TEST_DRIVE_SATISFACTION': '试驾低满',
        'SERVICE_SATISFACTION': '购车低满',
        'PICK_UP_CAR_SATISFACTION': '交付低满',
      }
      return val ? obj[val] || '' : ''
    }
  },
  data() {
    return {
      id: '',
      detail: {},
      submitParams: {
        followUserId: '',
        followUserMobile: '',
        followUserName: '',
        id: '',
        followRemark: ''
      },
      showFollow: true
    }
  },
  computed: {
    ...mapGetters(['dictHash','userInfo']),
    showFooter() {
      const { status } = this.detail
      return this.userInfo.type === 1 && status === 1
    },
    showFollows() {
      const { follows=[] } = this.detail
      return follows && follows.length > 0
    }
  },
  mounted() {
    const { id } = this.$route.query
    this.id = id
    this.submitParams.id = id
    this.getDetail()
  },
  methods: {
    // 获取详情
    async getDetail() {
      loading.showLoading()
      const res = await workOrderServices.workOrderDetail({ id: this.id })
      loading.hideLoading()
      this.detail = res
    },
    // 选择顾问
    onSelectSale() {
      selectSalesperson({ roleLogo: ['TEST_DRIVE_SATISFACTION', 'SERVICE_SATISFACTION'].includes(this.detail.eventType) ? '1014002,1014003' : '1014006,1014007' }).then((data) => {
        this.submitParams.followUserId = data.value
        this.submitParams.followUserName = data.dictName
        this.submitParams.followUserMobile = data.phone
      })
    },
    // 提交
    onSubmit() {
      this.$refs.form.validate().then(async () => {
        this.$dialog.confirm({
          title: this.$t('确认提交'),
          message: this.$t('请确认是否已经完成了对低满的处理，信息提交后将不能进行修改!'),
          confirmButtonColor: '#B9921A',
          beforeClose: async (action, done)=>{
            if (action === 'confirm') {
              const params = Object.assign({}, this.submitParams)
              try {
                await workOrderServices.workOrderFollow(params)
                done()
                this.$toast(this.$t('操作成功！'))
                setTimeout(() => {
                  if (window.history.length>1){
                    this.$router.go(-1)
                  } else {
                    window.location.reload()
                  }
                }, 1500)
              } catch ({ message }) {
                done()
              } 
            } else {
              done()
            }
          }
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.workOrderDetail{
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  color: #0d171a;
  font-size: 13px;
  .formBox{
    padding: 16px;
  }
    &.workOrderDetail_pd{
      padding-bottom: 96px;
    }
    .detail_item{
      margin-bottom: 16px;
      padding: 0 12px 12px;
      display: flex;
      flex-direction: column;
      &.special{
        .info{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .label{
            margin-bottom: 8px;
          }
        }
      }
      .row{
        display: flex;
      }
      .info{
        display: flex;
        padding-top: 8px;
        font-size: 13px;
        align-items: center;
        width: 100%;
        &.ellipsis{
          width: 50%;
          .label{
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .label{
          color: rgba(13, 23, 26, 0.45);
          max-width: 120px;
        }
        .content{
          flex: 1;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
        }
      }
      /deep/ .van-cell {
        padding: 10px 0;
      }
      .follow-item{
        background: #F4F8FE;
        margin-bottom: 10px;
        border-radius: 8px;
        overflow: hidden;
        padding: 12px;
        .item{
          display: flex;
          justify-content: space-between;
          word-break: break-all;
          &:not(:last-of-type) {
            margin-bottom: 8px;
          }
        }
      }
    }
}
</style>